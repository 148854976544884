import { v4 } from 'uuid'
import { DateTime } from 'luxon'
import themeColors from '@/scss/_colors-export.module.scss'
import { getDatetimeISOFromDateAndTimeStrings } from '@/utils/time'
import { store } from '@/state/store'
import { TripVehicle } from '@/classes/TripVehicle'

export class TripVehicleGroup {
  constructor({
    tripVehicleGroupHash = null,
    tripVehicleGroupIndex = null,
    tripVehicleGroupId = null,
    parentTripVehicleGroupId = null,
    tripVehicles = [],
    color = null,
    manuallySetName = null,
  }) {
    this.tripVehicleGroupHash = tripVehicleGroupHash || v4()
    this.tripVehicleGroupId = tripVehicleGroupId
    this.tripVehicles = tripVehicles.map((tv) => new TripVehicle({ ...tv }))
    this.tripDates = []
    this.finalDropoffDate = {
      date: null,
      time: null,
      tripAddressHash: null,
    }
    this.selected = false
    this.isActive = true
    this.parentTripVehicleGroupId = parentTripVehicleGroupId || null
    this.manuallySetName = manuallySetName
    if (color) {
      this.color = color
    } else {
      this.color =
        tripVehicleGroupIndex !== null
          ? colors[tripVehicleGroupIndex % colors.length]
          : colors[Math.floor(Math.random() * colors.length)]
    }
  }

  toObject() {
    const tripVehicleGroup = Object.assign({}, this)
    tripVehicleGroup.tripVehicles = this.tripVehicles.map((tripVehicle) =>
      tripVehicle.toObject()
    )
    tripVehicleGroup.tripDates = this.tripDates.map((tripDate) =>
      tripDate.toObject()
    )
    tripVehicleGroup.name = this.name
    tripVehicleGroup.tripVehicleGroupStops = this.tripVehicleGroupStops
    return tripVehicleGroup
  }

  toJSON() {
    return this.toObject()
  }

  set tripVehicleGroupStops(tripVehicleGroupStops) {
    return tripVehicleGroupStops
  }

  get name() {
    if (this.manuallySetName) {
      return this.manuallySetName
    }
    return this.defaultName
  }

  set name(name) {
    this.manuallySetName = name
  }

  get defaultName() {
    if (this.tripVehicles.length) {
      return this.tripVehicles.map((vehicle) => vehicle.name).join(' & ')
    }
    return 'Empty Group'
  }

  get tripVehicleGroupStops() {
    const trips = store.getters['quoteForm/getTrips']
    const trip = trips.find(({ tripId, tripVehicleGroups }) =>
      tripVehicleGroups.some(({ tripVehicleGroupHash }) => tripVehicleGroupHash === this.tripVehicleGroupHash)
    )
    const tripAddresses = trip?.tripAddresses
    let stops = []
    for (let date of this.tripDates) {
      const timezone = tripAddresses.find(
        (address) => address.tripAddressHash === date.tripAddressHash
      )?.address?.timeZone
      if (!timezone) {
        throw new Error('Timezone not found for trip address')
      }

      for (let time of date.times) {
        let stop = {}
        stop.pickupDatetime = getDatetimeISOFromDateAndTimeStrings(
          date.date,
          time,
          timezone
        )
        stop.pickupDate = date.date
        stop.pickupTime = time
        stop.active = true
        stop.tripAddressHash = date.tripAddressHash
        stop.tripVehicleGroupHash = this.tripVehicleGroupHash

        stops.push(stop)
      }
    }

    if (this.finalDropoffDate?.tripAddressHash) {
      const timezone = tripAddresses.find(
        (address) =>
          address.tripAddressHash === this.finalDropoffDate.tripAddressHash
      )?.address?.timeZone

      let stop = {}
      stop.dropoffDatetime = getDatetimeISOFromDateAndTimeStrings(
        this.finalDropoffDate.date,
        this.finalDropoffDate.time,
        timezone
      )
      stop.dropoffDate = this.finalDropoffDate.date
      stop.dropoffTime = this.finalDropoffDate.time
      stop.active = true
      stop.tripAddressHash = this.finalDropoffDate.tripAddressHash
      stop.tripVehicleGroupHash = this.tripVehicleGroupHash

      stops.push(stop)
    }

    stops = stops.sort(
      (a, b) =>
        DateTime.fromISO(
          a.pickupDatetime ? a.pickupDatetime : a.dropoffDatetime
        ) -
        DateTime.fromISO(
          b.pickupDatetime ? b.pickupDatetime : b.dropoffDatetime
        )
    )
    return stops
  }

  uuidv4() {
    return v4()
  }
}

const CRColors = {}
for (const [key, value] of Object.entries(themeColors)) {
  CRColors[key] = value
}

const colors = [
  CRColors['shuttle-purple'],
  CRColors['shuttle-yellow'],
  CRColors['shuttle-green'],
  CRColors['shuttle-blue'],
  CRColors['shuttle-red'],
  CRColors['shuttle-pink'],
  CRColors['shuttle-brat'],
]
