import { render, staticRenderFns } from "./ArrowSignIcon.vue?vue&type=template&id=99d13732&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d13732",
  null
  
)

export default component.exports