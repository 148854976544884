import { render, staticRenderFns } from "./CRTag.vue?vue&type=template&id=345cef29&scoped=true"
import script from "./CRTag.vue?vue&type=script&lang=js"
export * from "./CRTag.vue?vue&type=script&lang=js"
import style0 from "./CRTag.vue?vue&type=style&index=0&id=345cef29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345cef29",
  null
  
)

export default component.exports