import { SplitEvents, SplitKeyType, SplitTrafficType } from '@/utils/enum'
import { splitKey } from '@/utils/env'
import { datadogRum } from '@datadog/browser-rum'
import { SplitFactory } from '@splitsoftware/splitio'

export const state = {
  key: SplitKeyType.AnonymousUser,
  splitFactory: SplitFactory({
    core: {
      authorizationKey: splitKey(),
      trafficType: SplitTrafficType.User,
      key: SplitKeyType.AnonymousUser,
    },
    startup: {
      readyTimeout: 15,
      requestTimeoutBeforeReady: 5,
      retriesOnFailureBeforeReady: 2,
    },
    storage: {
      type: 'LOCALSTORAGE',
    },
    impressionListener: {
      logImpression(impressionData) {
        datadogRum
          .addFeatureFlagEvaluation(
            impressionData.impression.feature,
            impressionData.impression.treatment
          );
      },
    },
  }),
  splitClient: null,
  isReady: false,
}

export const getters = {
  isReady(state) {
    return state.isReady
  },
}

export const mutations = {
  SET_IS_READY(state, isReady) {
    state.isReady = isReady
  },
  SET_KEY(state, key) {
    state.key = key
  },
  SET_SPLIT_CLIENT(state, client) {
    state.splitClient = client
  },
  SET_SPLIT_FACTORY(state, factory) {
    state.splitFactory = factory
  },
}

export const actions = {
  async isFeatureEnabled({ state }, flag) {
    if (!state.isReady || !flag) {
      return false
    }
    const flagStatus = await state.splitClient.getTreatment(flag)
    const treatment = (await state.splitClient.getTreatment(flag)) === 'on'
    return treatment
  },
  async getAvailableTicketTypes({ state }) {
    if (!state.isReady) {
      return false
    }
    const result = await state.splitClient.getTreatmentWithConfig("available-coachrail-ticket-types-for-create")

    if (result.config == null) {
      return []
    }

    return JSON.parse(result.config).ticket_type_keys
  },
  async updateKey(
    { state, commit, dispatch },
    {
      key = SplitKeyType.AnonymousUser,
      forceRefresh = false
    }
  ) {
    const shouldReinitialize = state.key !== key
    commit('SET_KEY', key)
    if (shouldReinitialize || forceRefresh) {
      await dispatch('initializeClient')
    }
  },
  async initializeClient({ state, commit }) {
    if (state.splitClient !== null) {
      await state.splitClient.destroy()
      commit('SET_IS_READY', false)
      // destroying the client also destroys the factory
      commit(
        'SET_SPLIT_FACTORY',
        SplitFactory({
          core: {
            authorizationKey: splitKey(),
            trafficType: SplitTrafficType.User,
            key: state.key,
          },
          startup: {
            readyTimeout: 1.5,
          },
          impressionListener: {
            logImpression(impressionData) {
              datadogRum
                .addFeatureFlagEvaluation(
                  impressionData.impression.feature,
                  impressionData.impression.treatment
                );
            },
          },
        })
      )
    }

    try {
      const trafficType =
        state.key === SplitKeyType.AnonymousUser
          ? SplitTrafficType.Anonymous
          : SplitTrafficType.User
      const client = state.splitFactory.client(state.key, trafficType)
      await client.ready().catch((e) => {
        throw e
      })
      commit('SET_SPLIT_CLIENT', client)
      commit('SET_IS_READY', true)
    } catch (e) {
      console.warn(e)
    }
  },

  async trackQuoteConverted({ state }, properties) {
    const success = await state.splitClient.track(
      SplitEvents.QuoteConverted,
      properties.totalAmount,
      properties
    )
    return success
  },
}
