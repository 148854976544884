// Using some boilerplate from from:
// https://github.com/chrisvfritz/vue-enterprise-boilerplate

import router from '@/router/index'
import authService from '@/services/auth'
import { saveAuthToken } from '@/services/authTokenRepository'
import { baseUrl } from '@/utils/env'
import { clear, load, remove, save } from '@/utils/localStorage'
import { findPermissionByName } from '@/utils/permissions'
import axios from 'axios'
import Vue from 'vue'
import * as datadog from '../../utils/datadog'

export const state = {
  currentSession: load('auth.currentSession'),
}

const BROKER_USER_ROLE = 'is_broker_user'
const BROKER_ADMIN_ROLE = 'is_broker_admin'
const IS_KNOWN_ADDRESS_EDITOR = 'is_known_address_editor'
const IS_SENIOR_ISR = 'is_senior_isr'
const IS_MME_SALES = 'is_mme_sales'
const CAN_VIEW_OPS_TOTAL = 'can_view_ops_total'

const SDR_FLOW = 'hasSDRFlow'
const SDR_MANAGER_FLOW = 'hasSDRManagerFlow'
const L1_MANAGER_FLOW = 'hasL1ManagerFlow'
const RA_FLOW = 'hasRAFlow'
const RA_MANAGER_FLOW = 'hasRAManagerFlow'

const CAN_VIEW_PROVIDER_CALLER_ID = 'canViewProviderCallerId'

const CALL_CENTER_ACCESS = 'canViewCallCenter'

const CAN_MODIFY_USER_ROLES = 'canModifyUserRoles'

const CAN_VIEW_FEATURE_TOGGLES = 'canViewFeatureToggles'

const CAN_VIEW_CHATS = 'canViewChats'

export const mutations = {
  SET_CURRENT_SESSION(state, newValue) {
    state.currentSession = newValue
    save('auth.currentSession', newValue)
    save('cu-.userId', newValue.user ? newValue.user.userId : newValue.id)

    if (newValue.user) {
      const nameParts = []
      if (newValue.user.firstName) {
        nameParts.push(newValue.user.firstName)
      }
      if (newValue.user.lastName) {
        nameParts.push(newValue.user.lastName)
      }
      datadog.setUserContext(
        newValue.user.userId,
        newValue.user.email,
        nameParts.join(' ')
      )
    } else {
      datadog.clearUserContext()
    }
  },
  CLEAR_CURRENT_SESSION() {
    clear()
    datadog.clearUserContext()
  },
}

export const getters = {
  loggedIn(state) {
    return !!state.currentSession
  },
  currentUser(state) {
    if (!state.currentSession) {
      return null
    }
    return state.currentSession.user
  },
  currentUserProfile(state) {
    if (!state.currentSession) {
      return null
    }
    return state.currentSession.userProfile
  },
  isFlexUser(state) {
    if (!state.currentSession) {
      return false
    }
    if (!state.currentSession.userProfile) {
      return false
    }
    const userProfile = state.currentSession.userProfile
    if (Array.isArray(userProfile.roles)) {
      const roles = userProfile.roles
      return !!roles.find((role) => role.roleName === 'is_flex_user')
    }
    return false
  },
  currentUserID(state) {
    if (state.currentSession) {
      return null
    }
    return state.currentSession.user.userId
  },
  isBroker(state) {
    return state?.currentSession?.user?.company?.broker
  },
  isSDR(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, SDR_FLOW)
  },
  isRA(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, RA_FLOW)
  },
  isSDRManager(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, SDR_MANAGER_FLOW)
  },
  isL1Manager(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, L1_MANAGER_FLOW)
  },
  isRAManager(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, RA_MANAGER_FLOW)
  },
  isBrokerUser(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === BROKER_USER_ROLE
    )
  },
  isBrokerAdmin(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === BROKER_ADMIN_ROLE
    )
  },
  isKnownAddressEditor(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === IS_KNOWN_ADDRESS_EDITOR
    )
  },
  isSeniorISR(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === IS_SENIOR_ISR
    )
  },
  isMmeSales(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === IS_MME_SALES
    )
  },
  canViewOpsTotal(state) {
    return state?.currentSession?.userProfile?.roles?.some(
      (role) => role.roleName === CAN_VIEW_OPS_TOTAL
    )
  },
  isSuperAdmin(state) {
    return state?.currentSession?.user?.company?.isAdminCompany
  },
  hasManagerOverride(state) {
    if (!state.currentSession) {
      return false
    }
    const roles = state?.currentSession?.userProfile?.roles
    return !!roles?.find((role) => role.roleName === 'has_manager_override')
  },
  hasCallCenter(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, CALL_CENTER_ACCESS)
  },
  canModifyUserRoles(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, CAN_MODIFY_USER_ROLES)
  },
  canViewProviderCallerId(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, CAN_VIEW_PROVIDER_CALLER_ID)
  },
  canViewFeatureToggles(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, CAN_VIEW_FEATURE_TOGGLES)
  },
  canViewChats(state) {
    const roles = state?.currentSession?.userProfile?.roles
    return findPermissionByName(roles, CAN_VIEW_CHATS)
  },
  hasPermission(state) {
    return (permissionName) => {
      const roles = state?.currentSession?.userProfile?.roles
      return findPermissionByName(roles, permissionName)
    }
  },
}

export const actions = {
  init({ state, dispatch }) {
    dispatch('validate')
    dispatch('setUserProfile')
    dispatch('getUser')
  },

  async auth0Login({ commit, dispatch, getters }, token) {
    remove('auth.currentUser')

    try {
      saveAuthToken(token)

      const response = await authService.getUserProfileWithToken()
      const session = response.data
      await dispatch('setCurrentSession', session)
      await dispatch('setUserProfile')
      await dispatch('getUser')
      return session
    } catch (error) {
      // Handle error if needed
      console.error('Login error:', error)
      throw error // Re-throw error if you want to handle it elsewhere
    }
  },

  setCurrentSession({ commit }, sessionData) {
    commit('SET_CURRENT_SESSION', sessionData)
  },

  changePassword(store, payload) {
    const url = `https://${baseUrl()}/password/set`
    return axios.post(url, payload.payload)
  },

  adminChangePassword(store, payload) {
    const url = `https://${baseUrl()}/password/set/admin`
    return axios.post(url, payload.payload)
  },

  async logOut({ commit }) {
    commit('CLEAR_CURRENT_SESSION')
    if (Vue.prototype.$auth0 && Vue.prototype.$auth0.isAuthenticated) {
      await Vue.prototype.$auth0.logout()
    } else {
      router.push({ name: 'login' })
    }

  },

  forgotPassword(store, payload) {
    const url = `https://${baseUrl()}/user/forgotPassword`
    return axios.post(url, payload)
  },

  setPassword(store, payload) {
    const url = `https://${baseUrl()}/password/reset`
    return axios.post(url, payload)
  },

  // Legacy. Remove once it is no longer used.
  getCurrentUser() {
    const userId = localStorage.getItem('cu-.userId')
    const url = `https://${baseUrl()}/user/${userId}`
    return axios.get(url)
  },

  async setUserProfile({ commit, state }) {
    if (!state.currentSession) {
      return Promise.resolve(null)
    }
    try {
      const response = await authService.getUserRolesAndPermissions()
      const userProfile = response?.data?.userProfile
      commit(
        'SET_CURRENT_SESSION',
        Object.assign({}, state.currentSession || {}, {
          userProfile,
        })
      )
    } catch (error) {
      console.error('Error fetching user profile:', error)
      return error
    }
  },

  // Makes call to coachrail-users endpoint to retrieve user information
  async getUser({ commit, state }) {
    if (!state.currentSession) {
      return Promise.resolve(null)
    }

    try {
      const userId =
        state?.currentSession?.user?.userId || state?.currentSession?.id
      const url = `https://${baseUrl()}/user/${userId}`
      const response = await axios.get(url)
      const user = response?.data?.user
      commit(
        'SET_CURRENT_SESSION',
        Object.assign({}, state.currentSession || {}, {
          user,
        })
      )
    } catch (error) {
      console.error('Error fetching user:', error)
      return error
    }
  },

  validate({ commit, state }) {
    if (!state.currentSession) {
      return Promise.resolve(null)
    }
    return axios
      .get(
        `https://${baseUrl()}/user/${
          state.currentSession.user
            ? state.currentSession.user.userId
            : state.currentSession.id
        }`
      )

      .then((response) => {
        const user = response.data.user
        commit(
          'SET_CURRENT_SESSION',
          Object.assign({}, state.currentSession || {}, {
            user,
          })
        )
        return user
      })
      .catch(() => {
        return null
      })
  },
}
