// @checkjs: false

// Initialize Datadog before anything else
import { initDatadog } from './utils/datadog'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VTooltip from 'v-tooltip'

import { createPinia, PiniaVuePlugin } from 'pinia'

import '@/plugins/axios'
import '@/plugins/vuetify'
import '@/plugins/gmaps'
import '@/plugins/auth0'

import CR from './cr/plugin/index'
import { crSettings } from './cr/settings'

import { isAuth0TokenExpiringSoon } from '@/services/authTokenRepository'

import App from '@/App.vue'
import router from '@/router/index'
import { store } from '@/state/store'
import VueAudio from 'vue-audio-better'

import 'typeface-lato'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import '@/scss/main.scss'

import { dispatchActionForAllModules } from '@/utils/dispatch-action-for-all-modules'
import * as logger from './utils/logger'

// before anything else, initialize datadog integration
initDatadog()

Vue.use(VueMeta)
Vue.use(VTooltip)
Vue.use(VueAudio)
Vue.use(CR, crSettings)
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

// Don't warn about using the dev version of Vue in development.
// Vue.config.productionTip = process.env.NODE_ENV === 'production'

// This is a hack for Vue 2.6 to pass scoped slots
// to child components. This won't be needed in
// Vue 3.
// https://github.com/vuejs/vue/pull/7765#issuecomment-401985464
Vue.prototype._b = (function (bind) {
  return function (data, tag, value) {
    if (value && value.$scopedSlots) {
      data.scopedSlots = value.$scopedSlots
      delete value.$scopedSlots
    }
    return bind.apply(this, arguments)
  }
})(Vue.prototype._b)

// This is to silence this .native modifier warning,
// which is a bug in Vuetify 1.5 in that this warning shows up
// whenever the v-calendar component is used. This shouldn't be needed once we upgrade to
// Vuetify 2.0
// https://github.com/vuetifyjs/vuetify/issues/9999#issuecomment-569932219
const ignoreWarnMessage =
  'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

// Silences the setSelectionRange error on the quote form processing fees input
// so that we can have a mask on an input with type `number`. This error
// is thrown around when maskable.js tries to set the caret position.
// https://www.raymondcamden.com/2019/05/01/handling-errors-in-vuejs
const DOMExceptionMessage = `Uncaught InvalidStateError: Failed to execute 'setSelectionRange' on 'HTMLInputElement': The input element's type ('number') does not support selection.`
window.onerror = function (message, source, line, column, error) {
  if (message === DOMExceptionMessage) {
    return true
  }
}

// Register all global components.
// Global components start with CR
// e.g. CRInput, CRButton, etc.
const registerComponent = (fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName =
    // Gets the file name regardless of folder depth
    fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
}

let requireComponent
requireComponent = require.context(
  './components', // The relative path of the components folder
  false, // Whether or not to look in subfolders
  /CR[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
)
requireComponent.keys().forEach(registerComponent)

requireComponent = require.context(
  './components/charterup/core', // The relative path of the components folder
  false, // Whether or not to look in subfolders
  /[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
)
requireComponent.keys().forEach(registerComponent)

requireComponent = require.context(
  './cr/components', // The relative path of the CR component library components folder
  false, // Whether or not to look in subfolders
  /CR[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
)
requireComponent.keys().forEach(registerComponent)

async function initializeApp() {
  // Ensure Auth0 client is fully initialized before validating user
  // or starting router
  await Vue.prototype.$auth0.init();

  // We consider a token to be "expiring" if it has less than 12 hours left in its lifetime
  // and our refresh attempt fails. In that case, we should redirect the user to login.
  const willTokenExpire = await isAuth0TokenExpiringSoon()
  if (willTokenExpire) {
    await store.dispatch('auth/logOut')
    return
  }

  // Automatically run the `init` action for every module,
  // if one exists.
  dispatchActionForAllModules('init')

  new Vue({
    router,
    store,
    render: h => h(App),
    pinia
  }).$mount('#app');
}

// Call the initialization function to start the app
initializeApp();
