import { render, staticRenderFns } from "./TripTypeIcon.vue?vue&type=template&id=57c12db6&scoped=true"
var script = {}
import style0 from "./TripTypeIcon.vue?vue&type=style&index=0&id=57c12db6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c12db6",
  null
  
)

export default component.exports