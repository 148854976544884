import themeColors from '@/scss/_colors-export.module.scss'
import { toCamelCase } from '@/utils/string'

const colors = {}
for (const [key, value] of Object.entries(themeColors)) {
  const convertedKey = toCamelCase(`${key}`)
  colors[convertedKey] = value
}

export const crSettings = {
  breakpoints: {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920,
    mobile: 600,
  },
  theme: {
    ...colors,
  },
  sidebar: {
    width: 230,
    threshold: 'mdAndUp',
  },
}
