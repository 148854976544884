import { render, staticRenderFns } from "./GogoSupport.vue?vue&type=template&id=5137752d&scoped=true"
var script = {}
import style0 from "./GogoSupport.vue?vue&type=style&index=0&id=5137752d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5137752d",
  null
  
)

export default component.exports