<template>
  <div class="d-flex background-border-gray padding-x-2 border-radius-pill align-center justify-center">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="d-flex align-center justify-center background-primary border-radius-round margin-r-1 w-16 h-16"
        >
          <CRIcon
            width="12"
            height="12"
            view-box="1 0 20 22"
            color="white"
          >
            charter_up_c
          </CRIcon>
        </div>
      </template>
      <span class="text-white">CharterUP Rating</span>
    </v-tooltip>
    <StarRating
      :increment="0.1"
      :rating="rating"
      read-only
      :show-rating="false"
      :star-size="starSize"
      :active-color="$vuetify.theme.yellow"
      class="margin-r-1 margin-t-1 padding-a-0"
    />
    <div class="font-12">
      {{ formattedRating }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { alwaysShowTenthsPlace } from '@/utils/string'
import StarRating from 'vue-star-rating'

@Component({
  components: {
    StarRating,
  },
})
export default class CharterUPRating extends Vue {
  @Prop({ type: Number, default: 0 }) readonly rating: number
  @Prop({ type: Number, default: 14 }) readonly starSize: number

  get formattedRating(): string {
    return alwaysShowTenthsPlace(this.rating)
  }
}
</script>
