import { TripType } from "@/models/dto"

const SHUTTLE_TYPES = new Set(['shuttle', 'long_term_shuttle'])

/**
 * Validates that the given trip has a trip type defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if the trip has a trip type ID, `false` otherwise.
 */
export const hasTripType = (trip: any): boolean => {
  return !!trip?.tripType?.id || !!trip?.tripTypeId
}

/**
 * Validates that all required vehicles within the given trip have quantities and types defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if all required vehicles within the trip have quantities and vehicle type IDs,
 * `false` otherwise.
 */
export const hasVehicleQuantitiesAndTypes = (trip: any): boolean => {
  return trip?.requiredVehicles?.every(
    (vehicle) =>
      !!vehicle?.quantity && (!!vehicle?.vehicleType?.id || !!vehicle?.vehicleTypeId)
  )
}

/**
 * Validates that at the pickup stop on the trip has an address and date defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if the pickup stop on the trip has both an address and pickup or dropOff
 * date, `false` otherwise.
 */
export const hasPickupStopWithAddressAndDate = (trip: any): boolean => {
  return trip?.stops?.some((stop: any) => stop?.address?.country && (stop.pickupDate || stop.dropoffDate)
  || (trip.hasCirculation && (trip.circulationStartDate || trip.circulationEndDate) && trip?.stops?.some((stop: any) => stop?.address?.country)))
  || false
}

/**
 * Checks if a trip type is a Shuttle or Long Term Shuttle based on its trip type ID.
 * @param tripTypeId - The ID of the trip type.
 * @param tripTypes - An array of supported trip types.
 * @returns `true` if the trip type is a Shuttle or Long Term Shuttle, `false` otherwise.
 */
export const isShuttleTrip = (tripTypeId: number, tripTypes: TripType[]): boolean => {
  if (tripTypeId == null) {
    return false
  }
  const tripType = tripTypes.find(t => t.id === tripTypeId)
  return SHUTTLE_TYPES.has(tripType?.key)
}
