import { filter } from '@/utils/filter'
import calls from '@/services/calls'
import chats from '@/services/chats'
import Vue from 'vue'
import { TierKey } from '@/utils/enum'

export const EMPTY_CALL_CENTER = {
  call: {
    startTime: null,
    callerName: null,
    callerPhone: null,
    brandName: null,
    brandCallerId: null,
    brandPhone: null,
    callSid: null,
    isIncomingTransfer: false,
    isTransferred: false,
    currentQueue: null,
    queueTransferredFrom: null,
    parentBrandName: null,
    tierKey: null,
  },
  callback: {
    name: null,
    queueTargetName: null,
    queueTargetSid: null,
    placeCallRetry: null,
    reservation_sid: null,
    task_sid: null,
    queue_name: null,
    extension: null,
    to: null,
  },
  chats: [],
  lastChat: {
    channelSid: null,
    lookupCallerType: null,
    name: null,
    channelType: null,
    reservation_sid: null,
    task_sid: null,
    queue_name: null,
  },
  wrapUp: {
    task_sid: null,
    reservation_sid: null,
    call_sid: null,
  },
  user: {
    status: null,
    phone: null,
    contactUri: null,
    email: null,
    fullName: null,
  },
  teamStats: {
    longestHoldDuration: 0,
    callsInQueue: 0,
    agentsAvailable: 0,
    agentsOnCalls: 0,
  },
  agentAvailability: [],
  reservationSid: null,
  taskSid: null,
  isOnCall: false,
  isAuthenticated: false,
  isOnHold: false,
  isOnMute: false,
  isRinging: false,
  isCalling: false,
  isParked: false,
  hasMissedCall: false,
  hasCallback: false,
  isIncomingCallback: false,
  isIncomingChat: false,
  isInWrapUp: false,
  hasRequestedWrapUpExtension: false,
  reasonTypes: [],
  chatOutcomes: [],
  chatReasons: [],
  previousCall: {
    callSid: null,
    reservationSid: null,
    taskSid: null,
  },
  incomingChatName: null,
  chatWrapUpQueue: [],
}
const SDR_REASON_ORDER = [1, 7, 6, 5, 4, 3, 11, 10]
const RA_REASON_ORDER = [1, 8, 9, 7, 6, 5, 4, 3, 11, 10]
const STOP_CALL_RINGING_STATUSES = new Set(['Offline', 'Unavailable', 'Break'])

export const state = {
  callCenter: getSavedState('callCenter'),
}

export const mutations = {
  INITIALIZE_CALL_CENTER(state, callCenter) {
    state.callCenter = callCenter
    saveState('callCenter', callCenter)
  },
  CLEAR_CALL_CENTER() {
    window.localStorage.removeItem('callCenter')
  },
  SET_CALL(state, call) {
    state.callCenter.call = call
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_IS_TRANSFERRED(state, isTransferred) {
    state.callCenter.call.isTransferred = isTransferred
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_IS_INCOMING_TRANSFER(state, isIncomingTransfer) {
    state.callCenter.call.isIncomingTransfer = isIncomingTransfer
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_USER(state, user) {
    state.callCenter.user = user
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_USER_STATUS(state, status) {
    state.callCenter.user.status = status
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_IS_ON_CALL(state, isOnCall) {
    state.callCenter.isOnCall = isOnCall
    saveState('callCenter', state.callCenter)
    return state.callCenter.isOnCall
  },
  SET_IS_ON_HOLD(state, isOnHold) {
    state.callCenter.isOnHold = isOnHold
    saveState('callCenter', state.callCenter)
    return state.callCenter.setIsOnHold
  },
  SET_IS_ON_MUTE(state, isOnMute) {
    state.callCenter.isOnMute = isOnMute
    saveState('callCenter', state.callCenter)
    return state.callCenter.setIsOnMute
  },
  SET_IS_AUTHENTICATED(state, isAuthenticated) {
    state.callCenter.isAuthenticated = isAuthenticated
    saveState('callCenter', state.callCenter)
    return state.callCenter.isAuthenticated
  },
  SET_IS_RINGING(state, isRinging) {
    state.callCenter.isRinging = isRinging
    saveState('callCenter', state.callCenter)
    return state.callCenter.isRinging
  },
  SET_IS_CALLING(state, isCalling) {
    state.callCenter.isCalling = isCalling
    saveState('callCenter', state.callCenter)
    return state.callCenter.isCalling
  },
  SET_IS_PARKED(state, isParked) {
    state.callCenter.isParked = isParked
    saveState('callCenter', state.callCenter)
    return state.callCenter.isParked
  },
  SET_HAS_MISSED_CALL(state, hasMissedCall) {
    state.callCenter.hasMissedCall = hasMissedCall
    saveState('callCenter', state.callCenter)
    return state.callCenter.hasMissedCall
  },
  SET_RESERVATION_SID(state, reservationSid) {
    state.callCenter.reservationSid = reservationSid
    saveState('callCenter', state.callCenter)
    return state.callCenter.reservationSid
  },
  SET_TASK_SID(state, taskSid) {
    state.callCenter.taskSid = taskSid
    saveState('callCenter', state.callCenter)
    return state.callCenter.taskSid
  },
  SET_TEAM_STATS(state, teamStats) {
    state.callCenter.teamStats = teamStats
    saveState('callCenter', state.callCenter)
    return state.callCenter.teamStats
  },
  SET_AGENT_AVAILABILITY(state, agentAvailability) {
    state.callCenter.agentAvailability = agentAvailability
    saveState('callCenter', state.callCenter)
    return state.callCenter.agentAvailability
  },
  SET_REASON_TYPES(state, reasonTypes) {
    state.callCenter.reasonTypes = reasonTypes
    saveState('callCenter', state.callCenter)
    return state.callCenter.reasonTypes
  },
  SET_CALL_COMPLETE_REASON_TYPES(state, callCompleteReasonTypes) {
    state.callCenter.callCompleteReasonTypes = callCompleteReasonTypes
    saveState('callCenter', state.callCenter)
    return state.callCenter.callCompleteReasonTypes
  },
  SET_PREVIOUS_CALL(state) {
    state.callCenter.previousCall = {
      callSid: state.callCenter.callSid,
      reservationSid: state.callCenter?.reservationSid,
      taskSid: state.callCenter.taskSid,
    }
    saveState('callCenter', state.callCenter)
    return state.callCenter.previousCall
  },
  SET_CALLBACK(state, callback) {
    state.callCenter.callback = callback
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_HAS_CALLBACK(state, hasCallback) {
    state.callCenter.hasCallback = hasCallback
    saveState('callCenter', state.callCenter)
    return state.callCenter.hasCallback
  },
  SET_INCOMING_CALLBACK(state, isIncomingCallback) {
    state.callCenter.isIncomingCallback = isIncomingCallback
    saveState('callCenter', state.callCenter)
    return state.callCenter.isIncomingCallback
  },
  SET_CHATS(state, chats) {
    state.callCenter.chats = chats
    saveState('callCenter', state.callCenter)
    return state.callCenter
  },
  SET_INCOMING_CHAT(state, isIncomingChat) {
    state.callCenter.isIncomingChat = isIncomingChat
    saveState('callCenter', state.callCenter)
    return state.callCenter.isIncomingChat
  },
  SET_LAST_CHAT(state, lastChat) {
    state.callCenter.lastChat = lastChat
    saveState('callCenter', state.callCenter)
    return state.callCenter.lastChat
  },
  SET_CHAT_OUTCOMES(state, chatOutcomes) {
    state.callCenter.chatOutcomes = chatOutcomes
    saveState('callCenter', state.callCenter)
    return state.callCenter.chatOutcomes
  },
  SET_CHAT_REASONS(state, chatReasons) {
    state.callCenter.chatReasons = chatReasons
    saveState('callCenter', state.callCenter)
    return state.callCenter.chatReasons
  },
  SET_INCOMING_CHAT_NAME(state, incomingChatName) {
    state.callCenter.incomingChatName = incomingChatName
    saveState('callCenter', state.callCenter)
    return state.callCenter.incomingChatName
  },
  SET_WRAPUP(state, wrapUp) {
    state.callCenter.wrapUp = wrapUp
    saveState('callCenter', state.callCenter)
    return state.callCenter.wrapUp
  },
  SET_IS_IN_WRAPUP(state, isInWrapUp) {
    state.callCenter.isInWrapUp = isInWrapUp
    saveState('callCenter', state.callCenter)
    return state.callCenter.isInWrapUp
  },
  SET_HAS_REQUESTED_WRAPUP_EXTENSION(state, hasRequestedWrapUpExtension) {
    state.callCenter.hasRequestedWrapUpExtension = hasRequestedWrapUpExtension
    saveState('callCenter', state.callCenter)
    return state.callCenter.hasRequestedWrapUpExtension
  },
  ADD_TO_CHAT_WRAPUP_QUEUE(state, chat) {
    if (!state.callCenter.chatWrapUpQueue) {
      Vue.set(state.callCenter, 'chatWrapUpQueue', [])
    }
    state.callCenter.chatWrapUpQueue.push(chat)
    saveState('callCenter', state.callCenter)
    return state.callCenter.chatWrapUpQueue
  },
  REMOVE_FROM_WRAPUP_QUEUE(state) {
    const removedChat = state.callCenter.chatWrapUpQueue.shift()
    saveState('callCenter', state.callCenter)
    return removedChat
  },
}

export const getters = {
  callCenter(state) {
    return state.callCenter
  },
  call(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call
  },
  callback(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.callback
  },
  chats(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.chats
  },
  lastChat(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.lastChat
  },
  isTransferred(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.isTransferred
  },
  isIncomingTransfer(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.isIncomingTransfer
  },
  callCenterUser(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.user
  },
  callSid(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.callSid
  },
  reservationSid(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter?.reservationSid
  },
  taskSid(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.taskSid
  },
  callStartTime(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.startTime
  },
  callerName(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.callerName
  },
  callerPhone(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.callerPhone
  },
  brandName(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.brandName
  },
  brandCallerId(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.brandCallerId
  },
  parentBrandName(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.parentBrandName
  },
  brandPhone(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.brandPhone
  },
  isOnCall(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isOnCall
  },
  isOnHold(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isOnHold
  },
  isOnMute(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isOnMute
  },
  isAuthenticated(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isAuthenticated
  },
  isRinging(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isRinging
  },
  isCalling(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isCalling
  },
  isParked(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.isParked
  },
  hasMissedCall(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.hasMissedCall
  },
  teamStats(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.teamStats
  },
  reasonTypes(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.reasonTypes
  },
  agentAvailability(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.agentAvailability
  },
  hasCallback(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.hasCallback
  },
  isIncomingCallback(state) {
    if (!state.callCenter) {
      return false
    }

    return !!state.callCenter?.isIncomingCallback
  },
  hasChat(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.chats.length > 0
  },
  isIncomingChat(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.isIncomingChat
  },
  hasLastChat(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.lastChat?.channelSid
  },
  chatOutcomes(state) {
    if (!state.callCenter) {
      return false
    }
    return state.callCenter?.chatOutcomes
  },
  chatReasons(state) {
    if (!state.callCenter) {
      return false
    }
    return state.callCenter?.chatReasons
  },
  incomingChatName(state) {
    if (!state.callCenter) {
      return false
    }
    return state.callCenter?.incomingChatName
  },
  wrapUp(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.wrapUp
  },
  isInWrapUp(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.isInWrapUp
  },
  hasRequestedWrapUpExtension(state) {
    if (!state.callCenter) {
      return false
    }
    return !!state.callCenter?.hasRequestedWrapUpExtension
  },
  chatWrapUpQueue(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter?.chatWrapUpQueue
  },
  isVipCaller(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.tierKey === TierKey.Vip
  },
  isCharterUpPlusCaller(state) {
    if (!state.callCenter) {
      return null
    }
    return state.callCenter.call.tierKey === TierKey.Plus
  },
}

export const actions = {
  async initializeCallCenter(
    { commit, dispatch },
    callCenter = EMPTY_CALL_CENTER
  ) {
    const userId = getSavedState('auth.currentSession').user.id
    const filterObject = {
      column: {
        _t_id: 'calls_filter_1',
        filterType: 'eq',
        prop: 'answeredBy.userId',
      },
      value: userId,
    }
    const callsFilter = filter()
    const parentFilter = callsFilter.createParent('and')
    callsFilter.add(parentFilter, filterObject)
    const params = {
      page: 1,
      pageSize: 1,
      filters: callsFilter.asQueryParams(),
    }
    const callsResponse = await calls.getCalls(params, {
      root: true,
    })
    const lastCall = callsResponse.data.resultList?.[0]
    if (lastCall) {
      callCenter.call = {
        startTime: lastCall.createdOn,
        callerName: null,
        callerPhone: lastCall.callFrom,
        brandName: lastCall.phoneNumber.callerId,
        brandCallerId: lastCall.phoneNumber.callerId,
        brandPhone: lastCall.phoneNumber.phoneNumber,
        currentQueue: lastCall.queue,
        queueTransferredFrom: null,
        callSid: null,
        isTransferred: false,
        isIncomingTransfer: false,
      }
    }

    callCenter.reasonTypes = await dispatch('getReasonTypes')
    commit('INITIALIZE_CALL_CENTER', callCenter)
  },
  async getReasonTypes({ rootGetters }) {
    const reasonsResult = await calls.getCallReasonTypes({
      root: true,
    })
    const reasonTypes = reasonsResult.data.reasonTypes

    const isSDR = rootGetters['auth/isSDR']
    const isSDRManager = rootGetters['auth/isSDRManager']
    const isRA = rootGetters['auth/isRA']
    const isRAManager = rootGetters['auth/isRAManager']
    const orderArray =
      isSDR || isSDRManager
        ? SDR_REASON_ORDER
        : isRA || isRAManager
        ? RA_REASON_ORDER
        : null

    let filteredReasonTypes = []
    if (orderArray) {
      orderArray.forEach((id) => {
        const reasonTypeResult = reasonTypes.find((reasonType) => {
          return reasonType.id === id || reasonType.id > 10
        })
        if (reasonTypeResult) {
          filteredReasonTypes.push(reasonTypeResult)
        }
      })
    } else {
      filteredReasonTypes = reasonTypes
    }
    return filteredReasonTypes
  },
  async getCallCompleteReasonTypes() {
    const reasonsResult = await calls.getCallCompleteReasonTypes()
    const reasonTypes = reasonsResult.data
    return reasonTypes
  },
  clearCallCenter({ commit }) {
    commit('CLEAR_CALL_CENTER')
  },
  setCall({ commit }, call) {
    return commit('SET_CALL', call)
  },
  setIsTransferred({ commit }, isTransferred) {
    return commit('SET_IS_TRANSFERRED', isTransferred)
  },
  setIsIncomingTransfer({ commit }, isIncomingTransfer) {
    return commit('SET_IS_INCOMING_TRANSFER', isIncomingTransfer)
  },
  setCallCenterUser({ commit }, user) {
    if (STOP_CALL_RINGING_STATUSES.has(user?.status)) {
      commit('SET_IS_RINGING', false)
    }
    return commit('SET_USER', user)
  },
  setToIncomingCall({ commit }) {
    commit('SET_IS_ON_CALL', false)
    commit('SET_IS_ON_MUTE', false)
    commit('SET_IS_ON_HOLD', false)
    commit('SET_HAS_MISSED_CALL', false)
    commit('SET_IS_RINGING', true)
    commit('SET_IS_CALLING', false)
    commit('SET_INCOMING_CHAT', false)
  },
  setToAcceptedCallback({ commit }) {
    commit('SET_IS_ON_CALL', false)
    commit('SET_IS_ON_MUTE', false)
    commit('SET_IS_ON_HOLD', false)
    commit('SET_HAS_MISSED_CALL', false)
    commit('SET_IS_RINGING', false)
    commit('SET_IS_CALLING', false)
    commit('SET_INCOMING_CHAT', false)
  },
  setToOutboundCall({ commit }) {
    commit('SET_IS_ON_CALL', false)
    commit('SET_IS_ON_MUTE', false)
    commit('SET_IS_ON_HOLD', false)
    commit('SET_HAS_MISSED_CALL', false)
    commit('SET_IS_RINGING', false)
    commit('SET_IS_CALLING', true)
    commit('SET_INCOMING_CHAT', false)
  },
  setToMissedCall({ commit }) {
    commit('SET_IS_ON_CALL', false)
    commit('SET_IS_ON_HOLD', false)
    commit('SET_IS_RINGING', false)
    commit('SET_HAS_MISSED_CALL', true)
    commit('SET_IS_CALLING', false)
    commit('SET_INCOMING_CHAT', false)
  },
  setToOnCall({ commit }) {
    commit('SET_IS_ON_HOLD', false)
    commit('SET_IS_RINGING', false)
    commit('SET_HAS_MISSED_CALL', false)
    commit('SET_IS_ON_CALL', true)
    commit('SET_IS_CALLING', false)
    commit('SET_IS_PARKED', false)
    commit('SET_INCOMING_CHAT', false)
  },
  setToEndCall({ commit }) {
    commit('SET_IS_ON_HOLD', false)
    commit('SET_IS_ON_MUTE', false)
    commit('SET_IS_RINGING', false)
    commit('SET_HAS_MISSED_CALL', false)
    commit('SET_IS_ON_CALL', false)
    commit('SET_IS_CALLING', false)
    commit('SET_IS_PARKED', false)
    commit('SET_INCOMING_CHAT', false)
  },
  setIsOnCall({ commit }, isOnCall) {
    return commit('SET_IS_ON_CALL', isOnCall)
  },
  setIsOnHold({ commit }, isOnHold) {
    return commit('SET_IS_ON_HOLD', isOnHold)
  },
  setIsOnMute({ commit }, isOnMute) {
    return commit('SET_IS_ON_MUTE', isOnMute)
  },
  setIsAuthenticated({ commit }, isAuthenticated) {
    return commit('SET_IS_AUTHENTICATED', isAuthenticated)
  },
  setIsRinging({ commit }, isRinging) {
    return commit('SET_IS_RINGING', isRinging)
  },
  setIsCalling({ commit }, isCalling) {
    commit('SET_IS_CALLING', isCalling)
  },
  setIsParked({ commit }, isParked) {
    commit('SET_IS_PARKED', isParked)
  },
  setHasMissedCall({ commit }, hasMissedCall) {
    return commit('SET_HAS_MISSED_CALL', hasMissedCall)
  },
  setTaskSid({ commit }, taskSid) {
    return commit('SET_TASK_SID', taskSid)
  },
  setReservationSid({ commit }, reservationSid) {
    return commit('SET_RESERVATION_SID', reservationSid)
  },
  setTeamStats({ commit }, teamStats) {
    return commit('SET_TEAM_STATS', teamStats)
  },
  setAgentAvailability({ commit }, agentAvailability) {
    return commit('SET_AGENT_AVAILABILITY', agentAvailability)
  },
  setCallReasonTypes({ commit }, callReasonTypes) {
    commit('SET_REASON_TYPES', callReasonTypes)
  },
  setCallCompleteReasonTypes({ commit }, callCompleteReasonTypes) {
    commit('SET_CALL_COMPLETE_REASON_TYPES', callCompleteReasonTypes)
  },
  setPreviousCall({ commit }) {
    return commit('SET_PREVIOUS_CALL')
  },
  setUserStatus({ commit }, status) {
    return commit('SET_USER_STATUS', status)
  },
  setCallback({ commit }, callback) {
    return commit('SET_CALLBACK', callback)
  },
  setHasCallback({ commit }, hasCallback) {
    return commit('SET_HAS_CALLBACK', hasCallback)
  },
  setIsIncomingCallback({ commit }, isIncomingCallback) {
    return commit('SET_INCOMING_CALLBACK', isIncomingCallback)
  },
  setChats({ commit }, chats) {
    return commit('SET_CHATS', chats)
  },
  addChat({ commit, state }, chat) {
    const duplicateChats = state.callCenter.chats.filter(
      (c) => c.channelSid === chat.channelSid
    )
    if (duplicateChats.length === 0) {
      return commit('SET_CHATS', [...state.callCenter.chats, chat])
    }
  },
  removeChat({ commit, state }, chat) {
    const newChats = state.callCenter.chats.filter(
      (c) => c.channelSid !== chat.channelSid
    )
    commit('SET_CHATS', newChats)
    if (newChats.length === 0) {
      commit('SET_LAST_CHAT', chat)
    }
  },
  clearLastChat({ commit }) {
    commit('SET_LAST_CHAT', {
      channelSid: null,
      lookupCallerType: null,
      name: null,
      channelType: null,
      reservation_sid: null,
      task_sid: null,
      queue_name: null,
    })
  },
  setIsIncomingChat({ commit }, isIncomingChat) {
    return commit('SET_INCOMING_CHAT', isIncomingChat)
  },
  async initializeChatOutcomes({ commit }) {
    const results = await chats.getChatOutcomes()
    const chatOutcomes = results.data.data
    return commit('SET_CHAT_OUTCOMES', chatOutcomes)
  },
  async initializeChatReasons({ commit }) {
    const results = await chats.getChatReasons()
    const chatReasons = results.data.data
    return commit('SET_CHAT_REASONS', chatReasons)
  },
  setIncomingChatName({ commit }, incomingChatName) {
    return commit('SET_INCOMING_CHAT_NAME', incomingChatName)
  },
  setWrapUp({ commit }, wrapUp) {
    return commit('SET_WRAPUP', wrapUp)
  },
  setIsInWrapUp({ commit }, isInWrapUp) {
    if (isInWrapUp) {
      commit('SET_HAS_REQUESTED_WRAPUP_EXTENSION', false)
    }
    return commit('SET_IS_IN_WRAPUP', isInWrapUp)
  },
  setHasRequestedWrapUpExtension({ commit }, hasRequestedWrapUpExtension) {
    return commit(
      'SET_HAS_REQUESTED_WRAPUP_EXTENSION',
      hasRequestedWrapUpExtension
    )
  },
  addToChatWrapUpQueue({ commit }, chat) {
    commit('ADD_TO_CHAT_WRAPUP_QUEUE', chat)
  },
  removeFromChatWrapUpQueue({ commit }, chat) {
    return commit('REMOVE_FROM_WRAPUP_QUEUE', chat)
  },
  openCompleteChatDialog({ dispatch }, chat) {
    dispatch(
      'app/openDialog',
      {
        component: () => import('@/components/CallCenterCompleteChat.vue'),
        data: {
          finishedChat: chat,
        },
      },
      { root: true }
    )
  },
  openNextChatCompleteDialog({ dispatch, getters }) {
    dispatch('app/closeDialog', null, { root: true })
    if (getters.chatWrapUpQueue.length > 0) {
      const nextChat = getters.chatWrapUpQueue[0]
      dispatch('openCompleteChatDialog', nextChat)
    }
  },
}

// ===
// Private helpers
// ===
function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
