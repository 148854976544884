import { DayMap } from '@/models/DayMap'
import { DateTime, DateTimeFormatOptions, Duration, IANAZone, LocaleOptions } from 'luxon'
import { Trip } from '@/models/dto'

/**
 * Calculates and formats the relative time from now to the given datetime in hours and minutes.
 *
 * @param datetime - A string representing an ISO datetime.
 * @returns A string representing the relative time from now, formatted in hours and minutes if applicable.
 */
export const timeAgoInHoursAndMinutes = (datetime: string): string => {
  const units: Array<'hour' | 'minute' | 'second'> = ['hour', 'minute', 'second']

  const dateTime = DateTime.fromISO(datetime)
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  })

  const minutes = (diff.as(unit) % 1) * 60
  let result = relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
  if (unit === 'hour' && minutes <= -1) {
    const minutesAgo = relativeFormatter.format(Math.trunc(minutes), 'minute')
    result = result.replace(' ago', ` ${minutesAgo}`)
  }
  return result
}

/**
 * Calculates and formats the relative time from now to the given datetime.
 *
 * @param datetime - A string representing an ISO datetime.
 * @returns A string representing the relative time from now, formatted in the largest relevant unit (e.g., years, months, days).
 */
export const timeAgo = (datetime: string): string => {
  const units: Array<'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second'> = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']

  const dateTime = DateTime.fromISO(datetime)
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}

/**
 * Converts milliseconds to seconds.
 *
 * @param milliseconds - The number of milliseconds to convert. Defaults to 0.
 * @returns The equivalent number of seconds as a number.
 */
export const millisecondsToSeconds = (milliseconds: number = 0): number => {
  return Number(milliseconds) / 1000
}

/**
 * Converts seconds to minutes.
 *
 * @param seconds - The number of seconds to convert. Defaults to 0.
 * @returns The equivalent number of minutes as a number.
 */
export const secondsToMinutes = (seconds: number = 0): number => {
  return Number(seconds) / 60
}

/**
 * Converts seconds to hours.
 *
 * @param seconds - The number of seconds to convert. Defaults to 0.
 * @returns The equivalent number of hours as a number.
 */
export const secondsToHours = (seconds: number = 0): number => {
  return secondsToMinutes(seconds) / 60
}

/**
 * Converts hours to seconds.
 * @param hours The number of hours to convert.
 * @returns The equivalent number of seconds.
 */
export const hoursToSeconds = (hours: number): number => {
  return hours * 3600
}

/**
 * Converts seconds to days.
 *
 * @param seconds - The number of seconds to convert. Defaults to 0.
 * @returns The equivalent number of days as a number.
 */
export const secondsToDays = (seconds: number = 0): number => {
  return secondsToHours(seconds) / 24
}

/**
 * Converts an ISO date string to a more user-friendly date format.
 *
 * @param input - The input date as an ISO string.
 * @returns The date in a short, local format if the input is a string, otherwise, returns an empty string.
 */
export const friendlyDate = (input: string | any): string => {
  if (typeof input === 'string') {
    return DateTime.fromISO(input).toLocaleString(DateTime.DATE_SHORT as (LocaleOptions & DateTimeFormatOptions))
  }
  return ''
}

/**
 * Converts a time from 24-hour format to 12-hour format.
 *
 * @param time - The time in 24-hour format as a string.
 * @returns The time in 12-hour format with AM/PM notation.
 */
export const twentyFourHourToTwelveHourTime = (time: string | null | undefined): string => {
  const split = time?.split(':')
  if (split && split.length >= 2) {
    const hours = parseInt(split[0], 10)
    const minute = split[1]
    if (hours == 12) {
      return `12:${minute} PM`
    } else if (hours == 0) {
      return `12:${minute} AM`
    } else {
      return `${hours % 12}:${minute} ${hours < 12 ? 'AM' : 'PM'}`
    }
  }
  return time || ''
}

/**
 * Converts a date from YYYY-MM-DD format to MM/DD/YYYY format.
 *
 * @param date - The date in YYYY-MM-DD format.
 * @returns The date in MM/DD/YYYY format, or the original string if it doesn't match the expected format.
 */
export const yearMonthDayToMonthDayYear = (date: string | null | undefined): string => {
  const split = date?.split('-')
  if (split && split.length === 3) {
    return `${split[1]}/${split[2]}/${split[0]}`
  }
  return date || ''
}

/**
 * Returns the number of seconds from the current time to a specified future time in seconds.
 * @param seconds The future time in seconds.
 * @returns The number of seconds from now to the specified future time.
 */
export const secondsFromNow = (seconds: number): number => {
  return seconds - Date.now() / 1000
}

/**
 * Calculates the difference in days between the current date and a future date.
 * @param {string} futureDate - The future date to calculate the difference from. Should be in ISO 8601 format.
 * @returns {number|null} The rounded number of days between the current date and the future date. Returns null if the future date is not provided.
 */
export const diffInDaysFromNow = (futureDate: string | null): number | null => {
  if (!futureDate) {
    return null
  }

  const now = DateTime.local()
  const future = DateTime.fromISO(futureDate)

  if (!future.isValid) {
    console.error('Invalid date provided:', futureDate)
    return null
  }

  const diffInMilliseconds = future.toMillis() - now.toMillis()
  return Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24))
}

/**
 * Converts a datetime string to a short localized datetime format, including the local timezone.
 *
 * @param datetime - The datetime string to convert, in any valid datetime format.
 * @returns A string representing the date, time, and local timezone, or null if the input is invalid.
 *
 * @example
 * const datetime = '2022-12-01T12:30:00'
 * const shortLocalizedDateTime = datetimeToShortLocalizedDateTime(datetime)
 * // shortLocalizedDateTime might be '12/01/2022, 12:30 PM EDT' (assuming the current time in New York is 12:30 UTC)
 */
export const datetimeToShortLocalizedDateTime = (datetime: string): string | null => {
  if (!datetime) {
    return null
  }

  const dt = DateTime.fromISO(datetime, { zone: 'local' })
  if (!dt.isValid) {
    return null
  }

  return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS as (LocaleOptions & DateTimeFormatOptions))
}

/**
 * Converts seconds to a readable string expressing days, hours, and minutes.
 * @param seconds - The number of seconds to convert.
 * @returns A string representing the equivalent in days, hours, and minutes.
 */
export const secondsToDaysAndHoursString = (seconds: number = 0): string => {
  if (seconds <= 0) {
    return '' // Return empty string for non-positive seconds.
  }

  const fullDays = Math.floor(seconds / (3600 * 24))
  const fullHours = Math.floor((seconds % (3600 * 24)) / 3600)
  const fullMinutes = Math.floor((seconds % 3600) / 60)

  const daysString = fullDays > 0 ? `${fullDays} ${fullDays === 1 ? 'day' : 'days'}` : ''
  const hoursString = fullHours > 0 ? `${fullHours} ${fullHours === 1 ? 'hour' : 'hours'}` : ''
  const minutesString = fullMinutes > 0 ? `${fullMinutes} ${fullMinutes === 1 ? 'minute' : 'minutes'}` : ''

  const timeParts = [daysString, hoursString, minutesString].filter(part => part !== '')
  return timeParts.join(', ')
}


/**
 * Converts seconds into a shortened string format showing days, hours, and minutes.
 * @param seconds - The number of seconds to convert.
 * @returns A string in the format 'Xd Yh Zm', omitting any zero quantities.
 */
export const secondsToDaysAndHoursStringShortened = (seconds: number = 0): string => {
  if (seconds <= 0) {
    return ''; // Return an empty string for non-positive values
  }

  const fullDays = Math.floor(seconds / (3600 * 24));
  const fullHours = Math.floor((seconds % (3600 * 24)) / 3600);
  const fullMinutes = Math.ceil((seconds % 3600) / 60);

  const daysString = fullDays > 0 ? `${fullDays}d` : '';
  const hoursString = fullHours > 0 ? `${fullHours}h` : '';
  const minutesString = fullMinutes > 0 ? `${fullMinutes}m` : '';

  const arr: string[] = [];
  if (fullDays > 0) {
    arr.push(daysString);
  }
  if (fullHours > 0) {
    arr.push(hoursString);
  }
  if (fullMinutes > 0) {
    arr.push(minutesString);
  }

  return arr.join(' ');
}

/**
 * Calculates the number of days between two Date objects.
 * @param date1 The starting date.
 * @param date2 The ending date.
 * @returns The number of days between date1 and date2.
 */
export const numDaysBetweenDateTimes = (date1: Date, date2: Date): number => {
  const timeDiff = date2.getTime() - date1.getTime()
  return secondsToDays(millisecondsToSeconds(timeDiff))
}

/**
 * Calculates the number of minutes between two Date objects.
 * @param date1 The starting date.
 * @param date2 The ending date.
 * @returns The number of minutes between date1 and date2.
 */
export const numMinutesBetweenDateTimes = (date1: Date, date2: Date): number => {
  const timeDiff = date2.getTime() - date1.getTime()
  return secondsToMinutes(millisecondsToSeconds(timeDiff))
}

/**
 * Rounds a DateTime object to the nearest hour.
 * @param time The DateTime object to round.
 * @returns A new DateTime object rounded to the nearest hour.
 */
export const roundToNearestHour = (time: DateTime): DateTime => {
  if (time.minute < 30) {
    return time.set({ minute: 0 })
  }
  return time.set({ hour: time.hour + 1, minute: 0 })
}

/**
 * Rounds down a DateTime object to the start of the hour.
 * @param time The DateTime object to round down.
 * @returns A new DateTime object rounded down to the start of the hour.
 */
export const roundDownHour = (time: DateTime): DateTime => {
  return time.set({ minute: 0 })
}

/**
 * Rounds up a DateTime object to the start of the next hour.
 * @param time The DateTime object to round up.
 * @returns A new DateTime object rounded up to the start of the next hour.
 */
export const roundUpHour = (time: DateTime): DateTime => {
  return time.set({ hour: time.hour + 1, minute: 0 })
}

/**
 * Converts an ISO date string to a formatted string with optional timezone consideration.
 * @param date The ISO string representing the date.
 * @param timeZone The timezone to consider for formatting. If provided, includes timezone in the format.
 * @returns The formatted date string.
 */
export const isoToString = (date: string, timeZone?: string): string => {
  if (timeZone) {
    const datetime: DateTime = DateTime.fromISO(date, { zone: timeZone })
    return datetime.toFormat('M/dd/yyyy • t ZZZZ')
  }
  const datetime: DateTime = DateTime.fromISO(date)
  return datetime.toFormat('M/dd/yyyy • t')
}

/**
 * Calculates a new date offset by a specified number of days from a given ISO date string.
 * @param date The original date in ISO format.
 * @param offset The number of days to add to the date. Can be negative for past dates.
 * @returns The new date in ISO format.
 */
export const getOffsetDate = (date: string, offset: number): string => {
  return DateTime.fromISO(date).plus({ days: offset }).toISODate()
}

/**
 * Mapping of weekdays to their corresponding descriptions.
 */
export const daysMap: DayMap[] = [
  { day: 1, description: 'Monday' },
  { day: 2, description: 'Tuesday' },
  { day: 3, description: 'Wednesday' },
  { day: 4, description: 'Thursday' },
  { day: 5, description: 'Friday' },
  { day: 6, description: 'Saturday' },
  { day: 7, description: 'Sunday' },
]

/**
 * Converts a time object to a string representation.
 * The string contains the number of days, hours, minutes, and seconds in the time object.
 * If the time object contains a value for days, the string will not include seconds.
 *
 * @param timeObj - The time object to convert to a string, with possible keys for days, hours, minutes, and seconds.
 * @returns A string representation of the time object.
 *
 * @example
 * const timeObj = { days: 1, hours: 0, minutes: 0, seconds: 1 }
 * const timeString = timeObjectToString(timeObj)
 * console.log(timeString) // Outputs: '1d 0h 0m'
 */
export const timeObjectToString = (timeObj: { days?: number; hours?: number; minutes?: number; seconds?: number }): string => {
  const duration = Duration.fromObject(timeObj).shiftTo('days', 'hours', 'minutes', 'seconds')
  let timeString = `${duration.minutes}m`

  if (Math.abs(duration.hours) > 0) {
    timeString = `${duration.hours}h ${timeString}`
  }

  if (Math.abs(duration.days) > 0) {
    timeString = `${duration.days}d ${timeString}`
    // If days are present, we omit seconds.
  } else {
    // Only include seconds if there are no days.
    timeString += ` ${duration.seconds}s`
  }

  return timeString
}

/**
 * Converts an IANA timezone identifier to its corresponding timezone offset name.
 * @param ianaZone The IANA timezone identifier string.
 * @param dateTime The ISO date-time string to convert into an epoch value.
 * @returns The timezone offset name in short format.
 */
export const ianaZoneToOffsetName = (ianaZone: string, dateTime: string): string => {
  const date = DateTime.fromISO(dateTime, { zone: ianaZone })
  return IANAZone.create(ianaZone).offsetName(date.valueOf(), { format: 'short' })
}

/**
 * Formats an ISO date-time string for display by separating it into a short date and simple time.
 * @param dateTime The ISO date-time string to format.
 * @returns A string combining the date and time for user-friendly display.
 */
export const formatDateTimeForDisplay = (dateTime: string): string => {
  const date = DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT as (LocaleOptions & DateTimeFormatOptions))
  const time = DateTime.fromISO(dateTime).toLocaleString(DateTime.TIME_SIMPLE as (LocaleOptions & DateTimeFormatOptions))
  return `${date} ${time}`
}

export const isAnyTripStopDateInPast = (trips: Trip[]) : boolean => {
  if (!trips) {
    return false
  }

  let now = DateTime.local()

  for (const trip of trips) {
    for (const stop of trip.stops) {
      const timeZone = stop.address?.timeZone || null

      const pickup = stop.pickupDate && stop.pickupTime && timeZone
        ? DateTime.fromJSDate(new Date(`${stop.pickupDate}T${stop.pickupTime}:00`)).setZone(timeZone, { keepLocalTime: true }).toISO()
        : null

      const dropoff = stop.dropoffDate && stop.dropoffTime && timeZone
        ? DateTime.fromJSDate(new Date(`${stop.dropoffDate}T${stop.dropoffTime}:00`)).setZone(timeZone, { keepLocalTime: true }).toISO()
        : null

      const now = DateTime.local().setZone(timeZone).toISO()

      if ((pickup && pickup < now) || (dropoff && dropoff < now)) {
        return true
      }
    }
  }
  return false
}


/**
 * @deprecated Use getDatetimeISOFromDateAndTimeStrings instead.
 * Combines a date string and a time string into a datetime string in ISO format.
 *
 * @param date - The date string in 'YYYY-MM-DD' format.
 * @param time - The time string in 'HH:mm' format. Defaults to '12:00' if not provided.
 * @param timeZone - The time zone to set for the datetime.
 * @returns The combined datetime string in ISO format, or null if the date is not provided.
 */
export const getDatetimeFromDateAndTimeStrings = (
  date,
  time = '12:00',
  timeZone?
) => {
  if (!date) {
    return null
  }
  const iso = `${date}T${time}:00`
  const jsDate = new Date(iso)
  return DateTime.fromJSDate(jsDate)
    .setZone(timeZone, { keepLocalTime: true })
    .toISO()
}

/**
 * Converts a date and time string to an ISO string with the specified time zone.
 *
 * @param date - The date string in the format 'YYYY-MM-DD'.
 * @param time - The time string in the format 'HH:mm'. Defaults to '12:00'.
 * @param timeZone - The time zone identifier (e.g., 'America/New_York').
 * @returns The ISO string representation of the date and time in the specified time zone.
 * @throws Will throw an error if the date or timeZone is not provided.
 */
export const getDatetimeISOFromDateAndTimeStrings = (
  date: string,
  time: string = '12:00',
  timeZone: string
): string => {
  if (!date) {
    throw new Error('Date is required')
  }
  if (!timeZone) {
    throw new Error('Timezone is required')
  }

  const iso = `${date}T${time}:00`
  const jsDate = new Date(iso)
  return DateTime.fromJSDate(jsDate)
    .setZone(timeZone, { keepLocalTime: true })
    .toISO()
}


// Converts an ISO-formatted string to a hh:mm time string
export function convertIsoToTime(isoString: string, timeZone?: string): string {
  if (!timeZone) {
    timeZone = DateTime.local().zoneName
  }
  const dateTime = DateTime.fromISO(isoString, { zone: timeZone })
  return dateTime.toFormat('HH:mm')
}
